<template>
        <router-view></router-view>
</template>
<script>
import { Aside, Content } from './style';

const AuthLayout = {
  name: 'AuthLayout',
  components: { Aside, Content },
};

export default AuthLayout;
</script>
